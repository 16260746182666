import { Injectable } from '@angular/core';

import { RestService } from '../core/rest.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class StaffMemberService {
  constructor(private readonly rest: RestService) {}

  getMembers(businessId: number): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/staff/member?businessId=${businessId}`);
  }

  createMember(payload: any): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/staff/member`, payload);
  }

  updateMember(memberId: number, payload: any): Promise<any> {
    return this.rest.put(`${environment.apiUrl}/api/staff/member/${memberId}`, payload);
  }

  deleteMember(memberId: number): Promise<any> {
    return this.rest.delete(`${environment.apiUrl}/api/staff/member/${memberId}`);
  }
}
