import { Injectable } from '@angular/core';

import { RestService } from '../core/rest.service';

import { objectToQueryString } from '../../common/utils';

import { environment } from '../../../environments/environment';

@Injectable()
export class StaffBusinessService {
  constructor(private readonly rest: RestService) {}

  getBusinesses(params): Promise<any> {
    const query = objectToQueryString(params);

    return this.rest.get(`${environment.apiUrl}/api/staff/business${query}`);
  }

  getBusinessById(id: number): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/staff/business/${id}`);
  }

  createBusiness(payload: any): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/staff/business`, payload);
  }

  updateBusiness(businessId: number, payload: any): Promise<any> {
    return this.rest.put(`${environment.apiUrl}/api/staff/business/${businessId}`, payload);
  }

  deleteBusiness(businessId: number): Promise<any> {
    return this.rest.delete(`${environment.apiUrl}/api/staff/business/${businessId}`);
  }
}
