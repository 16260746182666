import { Injectable } from '@angular/core';

import { RestService } from '../core/rest.service';

import { environment } from '../../../environments/environment';
import { objectToQueryString } from '../../common/utils';

@Injectable()
export class StaffOrderService {
  constructor(private readonly rest: RestService) {}

  getOrders(where = null): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/staff/order${objectToQueryString(where)}`);
  }

  getOrderById(id: number): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/staff/order/${id}`);
  }

  createOrder(payload: any): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/staff/order`, payload);
  }

  updateOrder(id: number, payload): Promise<any> {
    return this.rest.put(`${environment.apiUrl}/api/staff/order/${id}`, payload);
  }
}
