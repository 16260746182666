import { Injectable } from '@angular/core';

import { RestService } from '../core/rest.service';

import { objectToQueryString } from '../../common/utils';

import { environment } from '../../../environments/environment';

@Injectable()
export class StaffLocationService {
  constructor(private readonly rest: RestService) {}

  getLocations(params: any): Promise<any> {
    const query = objectToQueryString(params);

    return this.rest.get(`${environment.apiUrl}/api/staff/location${query}`);
  }

  getLocationById(id: number): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/staff/location/${id}`);
  }

  createLocation(payload: any): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/staff/location`, payload);
  }

  updateLocation(id: number, payload: any): Promise<any> {
    return this.rest.put(`${environment.apiUrl}/api/staff/location/${id}`, payload);
  }

  deleteLocation(id: number): Promise<any> {
    return this.rest.delete(`${environment.apiUrl}/api/staff/location/${id}`);
  }
}
