import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

let dialogRef: MatDialogRef<UnsafeOperationDialog> = undefined;

export default function handleUnsafeOperationError(dialogService: MatDialog): void {
  /**
   * This method is called across several services that all use this error
   * handle function. Because Angular calls the getters and setter methods
   * that attempt to use localStorage, we want to make sure only one dialog box
   * is opened at any given time, rather than tens or hundreds at once.
   */
  if (dialogRef) return;
  dialogRef = dialogService.open(UnsafeOperationDialog, {
    disableClose: true,
  });

  dialogRef.afterClosed().pipe(finalize(() => (dialogRef = undefined)));
}

@Component({
  template: `
    <h5>Cookies Must Be Enabled</h5>
    <p>You must enable cookies to continue using this application.</p>
  `,
})
export class UnsafeOperationDialog {}
