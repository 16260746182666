import { Injectable } from '@angular/core';

import { RestService } from './rest.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class PaymentMethodService {
  constructor(private readonly rest: RestService) {}

  getStripeToken(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/payment-method/stripe/client-token`);
  }

  getPlaidToken(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/payment-method/plaid/client-token`);
  }

  getPaymentMethods(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/payment-method`);
  }

  createCardSource(payload: any): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/core/payment-method/!create-card-source`, payload);
  }

  exchangePlaidToken(payload: any): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/core/payment-method/!exchange-plaid-token`, payload);
  }

  setDefaultPaymentMethod(id: string): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/core/payment-method/!set-default-payment-method`, { id });
  }

  deletePaymentMethod(id: string): Promise<any> {
    return this.rest.delete(`${environment.apiUrl}/api/core/payment-method/${id}`);
  }
}
