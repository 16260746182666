import { Injectable } from '@angular/core';

import { RestService } from '../core/rest.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class StaffCalendarService {
  constructor(private readonly rest: RestService) {}

  async getCalendar(date: string): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/staff/calendar?date=${date}`);
  }
}
