import { Injectable } from '@angular/core';

import { RestService } from './rest.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class LutService {
  constructor(private readonly rest: RestService) {}

  getLicenseJurisdictions(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/lut/license-jurisdiction`);
  }

  getLicenseTypes(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/lut/license-type`);
  }

  getLifecycleStages(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/lut/lifecycle-stage`);
  }

  getStates(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/lut/state`);
  }
}
