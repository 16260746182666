import { Injectable } from '@angular/core';

import { RestService } from './rest.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class CalendarService {
  constructor(private readonly rest: RestService) {}

  async getCalendar(date: string): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/calendar?date=${date}`);
  }
}
