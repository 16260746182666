import { Injectable } from '@angular/core';

import { RestService } from '../core/rest.service';

import { objectToQueryString } from '../../common/utils';

import { environment } from '../../../environments/environment';

@Injectable()
export class StaffUserService {
  constructor(private readonly rest: RestService) {}

  getUsers(params: any = null): Promise<any> {
    if (params) {
      const queryParams = objectToQueryString(params);
      return this.rest.get(`${environment.apiUrl}/api/staff/user${queryParams}`);
    } else {
      return this.rest.get(`${environment.apiUrl}/api/staff/user`);
    }
  }

  getUserById(id: number): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/staff/user/${id}`);
  }

  createUser(payload: any): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/staff/user`, payload);
  }

  updateUser(id: number, payload): Promise<any> {
    return this.rest.put(`${environment.apiUrl}/api/staff/user/${id}`, payload);
  }
}
