import { Injectable } from '@angular/core';

import { RestService } from './rest.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class TenantService {
  constructor(private readonly rest: RestService) {}

  getRefTenant(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/tenant/ref`);
  }

  updateRefTenant(payload: any): Promise<any> {
    return this.rest.put(`${environment.apiUrl}/api/core/tenant/ref`, payload);
  }
}
