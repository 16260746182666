import { Injectable } from '@angular/core';

import { RestService } from '../core/rest.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class StaffTenantService {
  constructor(private readonly rest: RestService) {}

  getTenants(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/staff/tenant`);
  }

  getTenantById(id: number): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/staff/tenant/${id}`);
  }

  createTenant(payload: any): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/staff/tenant`, payload);
  }

  createStripeCustomer(id: number): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/staff/tenant/${id}/!createStripeCustomer`, {});
  }

  updateTenant(id: number, payload: any): Promise<any> {
    return this.rest.put(`${environment.apiUrl}/api/staff/tenant/${id}`, payload);
  }
}
