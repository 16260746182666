import { Injectable } from '@angular/core';

import { RestService } from './rest.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class LicenseService {
  constructor(private readonly rest: RestService) {}

  getLicenses(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/license`);
  }

  getLicenseById(id: number): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/license/${id}`);
  }

  createLicense(): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/core/license`, {});
  }

  updateLicense(licenseId: number, payload: any): Promise<any> {
    return this.rest.put(`${environment.apiUrl}/api/core/license/${licenseId}`, payload);
  }

  deleteLicense(licenseId: number): Promise<any> {
    return this.rest.delete(`${environment.apiUrl}/api/core/license/${licenseId}`);
  }
}
