import { Injectable } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private readonly snackBar: MatSnackBar) {}

  async createSnackBar(message: string) {
    this.snackBar.open(message, 'OK');
  }

  async createDynamicSnackBar(restResponse) {
    if (restResponse && restResponse.msg) {
      this.snackBar.open(restResponse.msg, 'OK');
    } else {
      return null;
    }
  }
}
