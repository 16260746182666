import { Injectable } from '@angular/core';

import { RestService } from './rest.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class LocationService {
  constructor(private readonly rest: RestService) {}

  getLocations(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/location`);
  }

  getLocationById(id: number): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/location/${id}`);
  }

  createLocation(): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/core/location`, {});
  }

  updateLocation(id: number, payload: any): Promise<any> {
    return this.rest.put(`${environment.apiUrl}/api/core/location/${id}`, payload);
  }

  deleteLocation(id: number): Promise<any> {
    return this.rest.delete(`${environment.apiUrl}/api/core/location/${id}`);
  }
}
