import { Injectable } from '@angular/core';

import { RestService } from './rest.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class BusinessService {
  constructor(private readonly rest: RestService) {}

  getBusinesses(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/business`);
  }

  getBusinessById(id: number): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/business/${id}`);
  }

  createBusiness(): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/core/business`, {});
  }

  updateBusiness(businessId: number, payload: any): Promise<any> {
    return this.rest.put(`${environment.apiUrl}/api/core/business/${businessId}`, payload);
  }

  deleteBusiness(businessId: number): Promise<any> {
    return this.rest.delete(`${environment.apiUrl}/api/core/business/${businessId}`);
  }
}
