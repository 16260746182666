import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    data: {
      title: 'Login',
    },
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
    data: {
      title: 'Reset Password',
    },
  },
  {
    path: 'reset-password-confirmation',
    loadChildren: () =>
      import('./reset-password-confirmation/reset-password-confirmation.module').then(
        (m) => m.ResetPasswordConfirmation
      ),
    data: {
      title: 'Reset Password Confirmation',
    },
  },
  {
    path: 'create-account',
    loadChildren: () => import('./create-account/create-account.module').then((m) => m.CreateAccountModule),
    data: {
      title: 'Create Account',
    },
  },
  {
    path: 'app',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
  },
  { path: '**', redirectTo: 'logout' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
