import { Injectable } from '@angular/core';

import { RestService } from '../core/rest.service';

import { objectToQueryString } from '../../common/utils';

import { environment } from '../../../environments/environment';

@Injectable()
export class StaffInvoiceService {
  constructor(private readonly rest: RestService) {}

  getInvoices(params: any = null): Promise<any> {
    const queryString = objectToQueryString(params);
    return this.rest.get(`${environment.apiUrl}/api/staff/invoice${queryString}`);
  }

  getSubscriptions(params: any = null): Promise<any> {
    const queryString = objectToQueryString(params);
    return this.rest.get(`${environment.apiUrl}/api/staff/subscription${queryString}`);
  }
}
